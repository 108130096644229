import { useEffect, useState } from "react";
import { baseUrl } from "../utils/helper";

const useStore = () => {
  const [login, setLogin] = useState(false);
  const [loader, setLoader] = useState(true);
  const [token, setToken] = useState("");
  const [profile, setProfile] = useState({});
  const [update, setUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [profileUpdater, setProfileUpdater] = useState(false);

  // new comment
  useEffect(() => {
    const refresh = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/auth/refresh`, {
          method: "GET",
          credentials: "include",
        });
        const resData = await res.json();
        if (res.ok) {
          setToken(resData.access_token);
        }
        setLoader(false);
      } catch (error) {
        console.error(error);
      }
    };
    if (!token) {
      refresh(); 
    }
  
    const interval = setInterval(refresh, 14 * 60 * 1000); 
    return () => clearInterval(interval);
  }, [setLoader,token]);
  
  return {
    login,
    setLogin,
    loader,
    profile,
    setProfile,
    token,
    setToken,
    update,
    setUpdate,
    searchQuery,
    setSearchQuery,
    profileUpdater, setProfileUpdater
  };
};

export default useStore;
