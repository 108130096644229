import React, {useState} from 'react';
import {links} from "../../utils/data";
import {BsLightningFill} from "react-icons/bs";
import {useLocation, useNavigate} from "react-router-dom";
import { Divider } from 'primereact/divider';


const Navigations = ({profile,setShow}) => {
    const {pathname} = useLocation()
    const navigate = useNavigate();
    const [selected, setSelected] = useState("");
    return (
        <div className="p-4 rounded-2xl relative z-20 mt-4">
            <ul>
                {links.map(({text, icon, path}, i) => {
                    const result=  ["Super Admin","Admin"].includes(profile?.role_type?.name)
                    if (
                        profile?.role_type?.name !== "Super Admin" &&
                        (text === "Add User")
                    ) {
                        return null;
                    }
                    if (
                        !result &&
                        text === "Add Product"
                    ) {
                        return null;
                    }
                    // comment
                    return (
                        <>
                            <li key={i}
                                className="py-2 hover:px-2 flex items-center justify-start transition-all duration-500">
                                {icon}
                                <button
                                    onClick={() => {
                                        setSelected(text);
                                        if (window.innerWidth < 500) {
                                            setShow(false);
                                        }
                                        navigate(path);
                                    }}
                                    className={`ml-2 w-full text-start flex text-gray-900 ${selected === text ? 'font-semibold' : ''} ${pathname === path ? 'font-semibold' : ''} items-center justify-between text-sm`}
                                >
                                    {text}
                                    {pathname === path && (
                                        <BsLightningFill className="text-blue-800"/>
                                    )}
                                </button>
                            </li>
                            <Divider style={{margin:'0 !important'}} type="solid" />
                        </>
                    );
                })}

            </ul>


        </div>
    );
};

export default Navigations;