import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../utils/helper";
import useData from "../../hooks/useData";
import PageHeading from "../../Components/PageHeading/PageHeading";

const Revenue = () => {
    let date = new Date();
    const {token, profile} = useData();
    const [fromDate, setFormDate] = useState();
    const [data, setData] = useState({});
    const [toDate, setToDate] = useState();

    const [reports,setReports] = useState({})
    const adminPermission = ["Admin", "Super Admin"].includes(profile?.role_type?.name);

    useEffect(() => {
        if (!adminPermission) {
            return;
        }
        const postData = {
            from: data?.from || "",
            to: data?.to || "",
        };
        const getReports = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/api/sales`, {
                    method: "PUT",
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                    body: JSON.stringify(postData),
                });
                const resData = await res.json();
                if (res.ok) {
                    setReports(resData.data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getReports().then();
    }, [token, data, adminPermission]);
    return (
        <div className='mt-14 p-4'>
            <PageHeading title={'Search Revenue'} sub={'Check you revenue here.'}/>
            <div className="grid grid-cols-12 gap-2 items-end">
                <div className="col-span-12 lg:col-span-3">
                    <label className={'text-sm '}>From</label>
                    <input
                        className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm"
                        type="date"
                        defaultValue={date.toDateString().substring(0, 10)}
                        onChange={(e) => setFormDate(e.target.value)}
                    />
                </div>
                <div className="col-span-12 lg:col-span-3">
                    <label className={'text-sm '}>To</label>
                    <input
                        className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm"
                        type="date"
                        defaultValue={date.toDateString().substring(0, 10)}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </div>
                <div className="col-span-12 lg:col-span-1">
                    <button
                        className="bg-blue-500 rounded-xl px-1 py-2 w-full text-white text-center"
                        onClick={() => {
                            if (fromDate && toDate) {
                                setData({...data, from: fromDate, to: toDate});
                            }
                        }}
                    >
                        Search
                    </button>
                </div>
            </div>
            <div className="mt-4">
                <h1 className="text-xl">
                    Total Sold Products :{" "}
                    <span className="font-bold">
                                      {reports?.no_of_sold_product}
                                    </span>
                </h1>
                <h1 className="text-xl">
                    Total Revenue:{" "}
                    <span className="font-bold">
                                      {reports?.total_amount}
                                    </span>{" "}
                    ৳
                </h1>
            </div>
        </div>
    );
};

export default Revenue;