import React from "react";
const PageHeading = ({title,sub,btn,dltF}) => {
    return (
        <div className={'mb-4 flex justify-between items-center'}>
            <div>
                <h1 className="text-lg font-semibold text-gray-700">{title}</h1>
                <p className={'-mt-1 text-gray-600 text-sm'}>{sub}</p>
            </div>
            <div>
                {btn && <button onClick={dltF} className={'px-4 text-white ring-1 ring-blue-200 focus:ring-2 text-sm py-2 w-full lg:w-fit bg-blue-500 cursor-pointer rounded-xl hover:bg-blue-600'}>{btn}</button>}
            </div>
        </div>
    )
}
export default PageHeading