import React, {useState} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {errorNotify, successNotify} from "../../utils/helper";
import useData from "../../hooks/useData";
import StockList from "./StockList";
import PageHeading from "../../Components/PageHeading/PageHeading";
import MButton from "../../Components/MButton/MButton";
import {useAddRequestMutation} from "../../rtk/api/stockApi";
import MInput from "../../Components/MInput/MInput";
import {Dialog} from "primereact/dialog";

const StockRequest = () => {
  const {token} = useData();
  const [reqData, setReqData] = useState({});
  const { update, setUpdate } = useData();
  const [addStorckRequest] = useAddRequestMutation()
  const [visible, setVisible] = useState(false);

  //HANDLE INPUTE STATE ADD
  const handleInput = (e) => {
    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };
  //ADD FOR STOCK REQUEST
  const addRequest = async (e) => {
    e.preventDefault();
    const postData = {
      name: reqData?.name,
      daily_requirement: Number(reqData?.requirment),
    };
    try {
      await addStorckRequest({url:'api/product_restock_request',token:token,data:JSON.stringify(postData)}).unwrap();
      successNotify("Request placed successfully");
      setReqData({});
      setVisible(false)
      e.target.reset();
    }catch (e) {
      errorNotify(e.message)
    }
  };
  return (
    <div className="raleway mt-14">
      <Dialog header="Stock Request" visible={visible} style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={() => {if (!visible) return; setVisible(false); }}>
        <form onSubmit={addRequest}>
          <MInput name={'name'} label={'Product Name'} func={handleInput} type={'text'} place={'Start from here'}/>
          <MInput name={'requirment'} label={'Daily Requirement'} func={handleInput} type={'number'} place={'Home many you need'}/>
          <MButton type={'submit'} label={"Request"}/>
        </form>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="p-4">
        <PageHeading btn={'Add Req'} dltF={()=>setVisible(true)} title={'Stock Request List'} sub={'A list of the requested products.'}/>
        <StockList update={update} setUpdate={setUpdate} />
      </div>
    </div>
  );
};

export default StockRequest;
