import React from 'react'
import useData from '../hooks/useData';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from '../Components/Loader/Loader';

const SuperAdminRoute = ({children}) => {
    const { loader,profile, token} = useData();
    const location = useLocation();
    if (loader && !token) {
        return <Loader/>;
    }
    if (!token && profile?.role_type?.name !== "Super Admin") {
        return <Navigate to="/login" state={{ from: location }} />;
    }
    return children;
};

export default SuperAdminRoute
