import React, {useState} from "react";
import useData from "../../hooks/useData";
import Product from "./Product";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
import Error from "../Error/Error";
import {CiSearch} from "react-icons/ci";
import {useGetProductsQuery} from "../../rtk/api/productsApi";
import {FaHeart, FaHeartbeat} from "react-icons/fa";

const CategoryWiseProduct = () => {
    const {update, setUpdate, token} = useData();
    const [selected, setSelected] = useState("All Time Favorite");
    const {data, isLoading: loading, error} = useGetProductsQuery({
        url: 'api/product/trending?page=0&size=100',
        token: token
    });
    const [searchText, setSearchText] = useState("");
    return (
        <div className='-mt-8'>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className={'sticky top-14 w-full'}>
                <div className={'border-b border-t block lg:flex items-center justify-between'}>
                    <div className={'flex items-center'}>
                        {[{icon: <FaHeart/>, text: "All Time Favorite"}, {
                            icon: <FaHeartbeat/>,
                            text: "Trending This Week"
                        }].map((item, i) => {
                            return (
                                <button
                                    className={`text-sm lg:text-base px-4 py-2 flex items-center pr-4 ${
                                        selected === item.text
                                            ? "text-indigo-700"
                                            : "text-gray-700"
                                    }`}
                                    onClick={() => setSelected(item.text)}
                                    key={i}
                                >   {item.icon}
                                    <span className='ml-2'>{item.text}</span>
                                </button>
                            );
                        })}
                    </div>
                    <div className={'border-l px-4 py-2'}>
                        <div className={'flex items-center relative mt-4  lg:mt-0 bg-gray-100 rounded-0 lg:rounded-xl'}>
                            {searchText.length > 0 && <span onClick={() => setSearchText("")}
                                                            className={'cursor-pointer text-sm underline text-gray-600 absolute top-2 right-4'}>clear</span>}
                            <button className={'pl-2'}><CiSearch size={20}/></button>
                            <input value={searchText} onChange={(e) => setSearchText(e.target.value)}
                                   className={'py-3 lg:py-2 px-2 lg:px-3 text-sm lg:text-base bg-transparent w-full focus:outline-none'}
                                   placeholder={'Search Product'}/>
                        </div>
                    </div>
                </div>
            </div>
            {selected === "Trending This Week" ? (
                <div className="mt-4 p-4">
                    {data?.data?.trending_this_week?.length > 0 ? <div>
                        {
                            !error ? <>
                                {
                                    !loading ? <div className="grid grid-cols-2 lg:grid-cols-7 gap-2">
                                        {
                                            data?.data?.trending_this_week?.filter(d => d.name.toLowerCase().includes(searchText.toLowerCase()))
                                                ?.map(
                                                    ({name, price, quantity, imageURL, id}, j) => (
                                                        <Product
                                                            key={j}
                                                            name={name}
                                                            price={price}
                                                            quantity={quantity}
                                                            imageURL={imageURL}
                                                            id={id}
                                                            update={update}
                                                            setUpdate={setUpdate}
                                                        />
                                                    )
                                                )}
                                    </div> : <Loader/>
                                }
                            </> : <Error error={error}/>
                        }
                    </div> : <div>
                        <h2 className='text-3xl font-black'>No items for this week</h2>
                    </div>}
                </div>
            ) : (
                <div className="mt-4 p-4">
                    {
                        !error ? <>
                            {
                                !loading ? <div className="grid grid-cols-2 lg:grid-cols-7 gap-2">
                                    {data?.data?.always_trending?.length > 0 &&
                                        data?.data?.always_trending?.filter(d => d.name.toLowerCase().includes(searchText.toLowerCase()))
                                            ?.map(({name, id, price, quantity, imageURL}, j) => (
                                                <Product
                                                    key={j}
                                                    name={name}
                                                    price={price}
                                                    quantity={quantity}
                                                    imageURL={imageURL}
                                                    id={id}
                                                    update={update}
                                                    setUpdate={setUpdate}
                                                />
                                            ))}
                                </div> : <Loader/>
                            }
                        </> : <Error error={error}/>
                    }
                </div>
            )}
        </div>
    );
};

export default CategoryWiseProduct;
