import React, {useEffect, useState} from "react";
import {BsBell} from "react-icons/bs";
import {HiBars3} from "react-icons/hi2";
import {baseUrl, errorNotify, formatTimeAgo,} from "../../utils/helper";
import "./Header.css";
import useData from "../../hooks/useData";
import {Link, useNavigate} from "react-router-dom";
import "rodal/lib/rodal.css";
import "react-toastify/dist/ReactToastify.css";
import {Dialog} from "primereact/dialog";
import {IoPowerOutline} from "react-icons/io5";


const Header = ({show, setShow}) => {
    const {token, profile, setProfile, setToken,profileUpdater} = useData();
    const [notification, setNotification] = useState(null);
    const [notificationSeen, setNotificationSeen] = useState(false);
    const [unSeenNotification, setUnSeenNotification] = useState(null);
    const image = require("./rasel.png");
    const navigate = useNavigate();
    const [showContent, setShowContent] = useState(false);
    useEffect(() => {
        const targetDate = new Date(2024, 9, 9);

        const checkDate = () => {
            const today = new Date();
            const isSameDay = today.getFullYear() === targetDate.getFullYear() &&
                today.getMonth() === targetDate.getMonth() &&
                today.getDate() === targetDate.getDate();

            setShowContent(isSameDay);
        };
        checkDate();
    }, []);
    useEffect(() => {
        const getProfile = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/api/profile`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                });
                const resData = await res.json();
                if (res.ok) {
                    setProfile(resData.data);
                }
            } catch (error) {
                errorNotify(error.message);
            }
        };
        getProfile().then();
    }, [token, setProfile,profileUpdater]);
    useEffect(() => {
        const getNotificationFrormRoute = async () => {
            const res = await fetch(
                `${baseUrl.url}/api/notification?page=0&size=100&sort=-id`,
                {
                    method: "Get",
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                }
            );
            if (res.ok && res.status !== 204) {
                const resData = await res.json();
                setNotification(resData.data?.items);
                const unseenNotification = resData?.data?.items?.filter(
                    (filterData) => filterData.is_seen === 0
                );
                setUnSeenNotification(unseenNotification);
            }
        };
        getNotificationFrormRoute().then();
    }, [token, notificationSeen]);
    const seeAllNotfi = async () => {
        const res = await fetch(`${baseUrl.url}/api/notification/make_seen`, {
            method: "Get",
            headers: {
                Authorization: `bearer ${token}`,
            },
        });
        if (res.status) {
            setNotificationSeen(!notificationSeen);
        }
    };
    const logout = async () => {
        try {
            const res = await fetch(`${baseUrl.url}/api/logout`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization: `bearer ${token}`,
                },
            });
            if (res.ok) {
                navigate("/login");
            } else {
                alert("Something went wrong");
            }
            setToken(null);
        } catch (error) {
            console.log(error);
        }
    };



    return (
        <div className="py-2 px-4 fixed w-full top-0 left-0 z-50 backdrop-blur-md bg-white/20 border-b">
            <Dialog header="সবাইকে শারদীয় দূর্গা পূজার শুভেচ্ছা " visible={showContent} style={{ width: window.innerWidth>700 ? '30vw' : '96%' }} onHide={() => setShowContent(false)}>
                <img src={'https://i.ibb.co.com/9rZLPVJ/Screenshot-from-2024-09-29-16-13-29.png'} alt={'Raktim birthday'} className={'w-full h-full'}/>
            </Dialog>
            <div className="grid grid-cols-12 items-center">
                <div className="col-span-6 lg:col-span-2 flex items-center">
                    <div className="h-8 w-8 flex items-center justify-center">
                        <HiBars3
                            onClick={() => setShow(!show)}
                            size={30}
                            className={`cursor-pointer ${show ? 'text-gray-900' : 'text-indigo-900'}`}
                        />
                    </div>
                    <Link to="/">
                        <img src={image} alt="" className="h-10 ml-4"/>
                    </Link>
                </div>
                <div className="hidden lg:block col-span-8 lg:col-span-8"></div>
                <div className="col-span-6 lg:col-span-2 flex items-center justify-end">
                    <div className="mr-4">
                        <div className=" relative group">
                            <div title="Notifications">
                                <BsBell
                                    size={20}
                                    className={`cursor-pointer -mb-1 relative ${
                                        unSeenNotification?.length > 0
                                            ? "text-blue-500"
                                            : "text-gray-700"
                                    }`}
                                />
                                <p className="absolute -top-3 left-3 w-4 h-4 rounded-full bg-blue-100  flex items-center text-xs justify-center text-blue-500">
                                    {unSeenNotification?.length>0 ? unSeenNotification?.length : 0}
                                </p>
                            </div>
                            <div
                                className="shadow-2xl bg-white text-slate-800 rounded-lg overflow-hidden z-50 w-80 absolute top-full md:right-0 -right-[64px] hidden group-hover:block">
                                <div className="flex items-center justify-between p-2 border-b bg-blue-500 text-white">
                                    <h1 className="text-md font-medium dark:text-yellow-500">
                                        Notification
                                    </h1>
                                    <button
                                        onClick={seeAllNotfi}
                                        className="text-white text-xs"
                                    >
                                        Mark all as read
                                    </button>
                                </div>
                                {notification?.length > 0 ? (
                                    <div className="max-h-[350px] overflow-auto scrollBar shadow-lg">
                                        {notification?.map((notifi, i) => {
                                            const time = formatTimeAgo(notifi?.created_at);
                                            return (
                                                <div key={i}>
                                                    <div
                                                        className={`${
                                                            notifi?.is_seen === 0 && "bg-orange-50"
                                                        } p-4 grid grid-cols-12 items-start py-2  border-b`}
                                                    >
                                                        <div className="col-span-12">
                                                            <p className="font-medium text-sm text-blue-500">
                                                                {notifi?.title}
                                                            </p>
                                                            <p className="pt-1 text-xs">{notifi.message}</p>
                                                            <div className="flex items-center">
                                <span className="text-xs text-gray-500">
                                  {time}
                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="relative h-40 bg-white">
                                        <div className="absolute z-50 w-full h-full p-4">
                                            <h1 className="font-bold text-slate-700">
                                                No notification
                                            </h1>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className="flex items-center cursor-pointer gap-1"
                        onClick={()=>{
                            navigate("/profile")
                        }}
                    >
                        <img
                            src={`${baseUrl.url}${profile?.imageURL}`}
                            alt="profile"
                            className="h-8 mx-4 w-8 object-cover rounded-full"
                        />
                        <IoPowerOutline onClick={logout} className={'cursor-pointer'} size={20}/>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
